<template>
  <div class="col-12 c g">
    <div class="card">
      <div class="card-header text-center">
        <h4>
          <i class="fa fa-list"></i>
          اولياء الامور &nbsp;
          <input
            type="text"
            v-model="search"
            @change="getParents()"
            style="border: 1px solid #ddd; padding: 3px"
            placeholder="ابحث بالاسم او بالجوال..."
          />
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <th>الاسم</th>
              <th>الجوال</th>
              <th>ارسال اشعارات</th>
              <th>ملاحظات</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="bus in parents" :key="bus._id">
                <td>
                  <a
                    href="javascript:;"
                    @click="$router.push('/parents/overview/' + bus._id)"
                  >
                    <i class="fa fa-user"></i>
                    {{ bus.name }}
                  </a>
                </td>
                <td>
                  {{ bus.phone }}
                </td>
                <td>
                  <span v-if="bus.token && bus.token != ''">
                    <i class="fa fa-check text-success"></i>
                  </span>
                </td>
                <td>
                  {{ bus.notes }}
                </td>
                <td>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="$router.push('/parents/add-or-edit#' + bus._id)"
                  >
                    <i class="fa fa-edit"></i>
                    تعديل
                  </button>
                  &nbsp;
                  <button
                    class="btn text-danger btn-sm"
                    @click="deleteBus(bus)"
                  >
                    <i class="fa fa-trash"></i>
                    حذف
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BModal,
    draggable,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      loading: false,
      user: JSON.parse(localStorage.getItem("user")),
      parents: [],
      search: null,
    };
  },
  created() {
    if (!checkPer("parents-view")) {
      this.$router.push("/per");
      return false;
    }
    var g = this;
    g.getParents();
  },
  methods: {
    checkPer(e) {
      return checkPer(e);
    },
    getParents() {
      var g = this;
      $.post(api + "/user/parents/list", {
        jwt: g.user.jwt,
        search: g.search,
      })
        .then(function (r) {
          g.parents = r.response;
        })
        .catch(function (e) {
          console.log(e);
          alert("حدث خطأ..");
        });
    },
    deleteBus(bus) {
      var g = this;
      if (
        confirm("متأكد من حذف ولي الامر نهائياً؟ ربما يؤثر على باقي السجلات")
      ) {
        $.post(api + "/user/parents/delete", {
          jwt: g.user.jwt,
          id: bus._id,
        })
          .then(function (r) {
            location.reload();
          })
          .catch(function (e) {
            console.log(e);
            alert("حدث خطأ..");
          });
      }
    },
    groupName(id) {
      return this.groups[id] ? this.groups[id].title : "لا يوجد";
    },
  },
};
</script>